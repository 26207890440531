
.section-image {
  position: relative;
  background-size: cover;
  background-position: 50%;

  &:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background-color: rgba(0,0,0,.5);
  }
}
