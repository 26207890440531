.media {
  .avatar {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    margin-right: 15px;

    img {
      width: 100%;
      border-radius: 50%;
    }
  }

    textarea {
        margin-bottom: 20px;
    }

    .media-heading small {
        font-family: $font-family-base;
    }
    .media-body {
        padding-right: 10px;

        .media .media-body {
            padding-right: 0px;
        }
    }
    &.media-post {
        .form-group,
        .input-group {
            margin-top: 25px;
        }
    }
    .media-footer {
        .btn{
          margin-bottom: 20px;
          i {
            position: relative;
            top: 1px;
          }
        }

        &:after {
            display: table;
            content: " ";
            clear: both;
        }
    }
    p {
        line-height: 1.61em;
        font-size: 0.875rem;
    }
}


.media-header {
  .image {
    margin: 20px auto 30px;

    img {
      width: 100%;
      border-radius: 6px;
    }
  }

  .description {
    text-align: justify;
  }

  .hashtag {
    a {
      cursor: pointer;
    }
  }

  .btn-group {
    .btn i {
      top: 3px;
      position: relative;
    }
  }

}


.text {
  position: relative;
  left: 15px;
  top: 1px;
}
.name {
  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.15;
  max-width: 11em;
  overflow: hidden;
  padding-top: 3px;
  text-overflow: ellipsis;
}
.meta {
  color: #a49e9e;
  font-size: .8em;
}

// CHAT


.chat {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  @include media-breakpoint-up(lg) {
    .section {
      min-height: 100vh;
      max-height: 999px;
    }
  }

  .media-body {
    span {
      font-size: 14px;
    }
  }

  .list-group {
    padding: 10px;

    .list-group-item:not(.active) {
      background: transparent;
    }

    .avatar {
      width: 40px !important;
      height: 40px !important;
    }

    .badge {
      padding: 4px;
      margin-top: 3px;
      display: inline-block;
      float: right;
    }

    .list-group-item {

      &.active {
        border-radius: $border-radius-lg;
      }

      &:nth-of-type(2) {
        border-top: 0;
      }
    }
  }

  .card {
    max-height: 70vh;
    overflow: auto;
    margin-top: 30px;
    overflow-x: hidden;

    .card-header,
    .card-footer {
      background: transparent;
      display: flex;
    }

    .far {
      margin-right: 3px;
    }
  }

  .spinner {
    width: 70px;
    text-align: center;
    display: inline-block;

    > div {
      width: 7px;
      height: 7px;
      background-color: $gray-300;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }


  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
}

//
// Media comment
//


.media-comment {
	margin-top: 2rem;
}

.media-comment-avatar {
  margin-top: -1rem;
  margin-right: -2rem;
  position: relative;
  z-index: 1;
  width: 64px;
  height: 64px;
  border: 4px solid $white;
  @include transition($transition-base);
}

.media-comment-text {
	border-radius: $border-radius-lg;
	border-top-left-radius: 0;
	position: relative;
	background-color: $gray-100;
	padding: 1rem 1.25rem 1rem 2.5rem;
}

.media-comment {
	&:hover {
		.media-comment-avatar {
			transform: scale(1.1);
		}
	}
}

.card-header {
  .icon-big i {
    font-size: 12px;
    top: 1px;
  }
}
