//
// Table
//

// General styles

.table {
    thead th {
        padding-top: $table-head-spacer-y;
        padding-bottom: $table-head-spacer-y;
        font-size: $table-head-font-size;
        text-transform: $table-head-text-transform;
        letter-spacing: $table-head-letter-spacing;
        border-bottom: $table-border-width solid $table-border-color;
    }

    th {
        font-weight: $table-head-font-weight;
    }

    td {
        .progress {
            height: 3px;
            width: 120px;
            margin: 0;
        }
    }

    td,
    th {
        font-size: $table-body-font-size;
        white-space: nowrap;
    }


    // Vetical align table content

    &.align-items-center {
        td,
        th {
            vertical-align: middle;
        }
    }


   // Styles for dark table

   .thead-dark {
        th {
            background-color: $table-dark-head-bg;
            color: $table-dark-head-color;

            a {
                color: $table-dark-head-color;
            }
        }
    }


    // Styles for light table

    .thead-light {
        th {
            background-color: $white;
            color: $table-head-color;

            a {
                color: $table-head-color;
            }
        }
    }


}


// Add transition for hover state

.table-hover {
    tr {
        @include transition($transition-base);
    }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F6F9FC;
}


// Flush tables

.table-flush {
    td,
    th {
        border-left: 0;
        border-right: 0;
    }

    tbody {
        tr {
            &:first-child {
                td,
                th {
                    border-top: 0;
                }
            }

            &:last-child {
                td,
                th {
                    border-bottom: 0;
                }
            }
        }
    }
}


.table-shopping {

  tbody td {
    vertical-align: middle;
  }

    .td-name {
        font-weight: $font-weight-normal;
        font-size: 1.5em;

        small {
            font-size: 0.75em;
            font-weight: $font-weight-light;
        }
    }
    .td-number {
       font-weight: $font-weight-light;
       font-size: $h4-font-size;

       .btn-group {
         i {
          top: 2px;
          position: relative;
         }
       }
   }
    .td-name {
        max-width: 200px;
        overflow: hidden;
    }
    .td-number {
        text-align: right;
        min-width: 145px;

        small {
            margin-right: 3px;
        }
    }

    .td-price {
      font-size: 26px;
      font-weight: 300;
      margin-top: 5px;
      position: relative;
      top: 4px;
      text-align: right;
    }

    .img-container {
        width: 120px;
        max-height: 160px;
        overflow: hidden;
        display: block;

        img{
            width: 100%;
        }
    }
}

#tables .table-responsive{
    margin-bottom: 30px;
}

// Fixes for custom elements inside tables

.table {
    .custom-toggle {
        display: block;
    }
}
//
// Sortable tables with list.js
//

.table  {
    [data-sort] {
        cursor: pointer;
    }

    .thead-dark {
        [data-sort] {
            &::after {
                content: str-replace(url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$table-dark-head-color}'/></svg>"), "#", "%23");
                margin-left: .25rem;
            }
        }
    }

    .thead-light {
        [data-sort] {
            &::after {
                content: str-replace(url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$table-head-color}'/></svg>"), "#", "%23");
                margin-left: .25rem;
            }
        }
    }
}
