// Cards


@import "cards/card-register";
@import "cards/card-blog";
@import "cards/card-profile";
@import "cards/card-pricing";
@import "cards/card-rotate";
@import "cards/card-background";
@import "cards/card-project";
@import "cards/card-product";


//
// Card
//

.card {
  margin-bottom: $grid-gutter-width;

  @if $enable-shadows {
    box-shadow: $card-box-shadow;
    border: 0;
  }

  .card-title {
      margin-bottom: .5rem;
  }

  .card-header,
  .card-footer {
    background-color: $white;
  }

  .card-img-overlay {
    .content {
      z-index: 2;
    }

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      z-index: 1;
      width: 100%;
      display: block;
      content: "";
      background: rgba(0,0,0,.5);
      border-top-left-radius: .25rem!important;
      border-top-right-radius: .25rem!important;
    }
  }

  &[data-header="skew"] {

    .card-header {
      height: 100%;
      margin-bottom: 0;
      padding: 0;

      &::after {
        content: "";
        width: 24%;
        overflow: hidden;
        position: absolute;
        left: 37%;
        top: 7%;
        height: 96%;
        background-color: $white;
        transform: rotate(80deg);
        z-index: 1;
      }
    }

    .card-category {
      z-index: 2;
      position: relative;
    }
  }

  &[data-background="pattern"] {

    .pattern {
      position: absolute;
      opacity: .1;
      bottom: 10%;
    }
  }

  &[data-header="pattern"] {

    .pattern {
      position: absolute;
      bottom: 60%;
    }

    .card-body {
      padding-top: 10rem;
    }
  }
}

.card-translucent {
    background-color: rgba(18, 91, 152, 0.08);
}

.card-img-top {
    @if ($enable-shadows == false) {
        width: calc(100% + 2px);
        margin-left: -1px;
        margin-top: -1px;
    }
}

.card-img-bottom {
    @if ($enable-shadows == false) {
        width: calc(100% + 2px);
        margin-left: -1px;
        margin-bottom: -1px;
    }
}

// Card decks

.card-deck  {
  .card {
    margin-bottom: $grid-gutter-width;
  }
}


// Card with shdadow

.card {
  &.shadow {
    border: 0 !important;
  }
}


//
// Columns
//

.card-columns {
  @include media-breakpoint-up(sm) {
    column-count: 1;
  }

  @include media-breakpoint-up(md) {
    column-count: 2;
  }

  @include media-breakpoint-up(xl) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
  }
}
