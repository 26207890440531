.App {
  text-align: center;
  background-color: #464646;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 768px) {
  .mobile-hide {
    display: none;
  }
  
}

.gallery-list {
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .gallery-list {
    display: block;
  }
}

/* In your CSS file */
.banner-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 0 auto;
  text-align: center;
  z-index: 0;
}

.banner-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  position: relative;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
  background-position: 50%;
  margin: 0 auto;
  text-align: center;
  filter: blur(23px) brightness(1);
  z-index: 1;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .banner-container {
    width: 100%;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

code {
  color: #c9c9c9;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
.image-card {
  cursor: pointer;
}

.image-card:hover img {
  transform: scale(1.05);
}

.image-card:hover {
  border-radius: 0.25rem;
  border: 2px solid #0062ff !important;
}

.image-card-gallery {
  cursor: pointer;
  border: 2px solid #38404e !important;
}

.image-card-gallery:hover img {
  transform: scale(1.05);
}

.image-card-gallery:hover {
  border-radius: 0.25rem;
  border: 2px solid #0062ff !important;
}

.image-gallery-card {
  border: 2px solid #38404e !important;
}


@media (max-width: 575.98px) {
  .my-gallery {
    margin-top: -50px !important;
  }

  .container-margin {
    margin-top: 0px !important;
  }
} */