.brandCollapes {
    height: 50px !important;
}

.mainLogo {
    height: 40px !important;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.navbar.navbar-main {
    z-index: 105;
}

.pointer {
    cursor: pointer;
}

.section-shaped .shape-style-1.shape-default {
    background: linear-gradient(150deg, #181f38 50%, #181f38 70%, #181f38 94%) !important;
}

.headroom--not-top {
    background-color: #000000 !important;
}

.myGlobe {
    position: absolute;
    top: -130px;
    margin-left: 30px;
    z-index: -1;

}

.zUp {
    z-index: 10;
}

.btn-inner--text a {
    color: white;
}

.appImageHeader {
    position: absolute;
    top: 30px;
    z-index: 100;
    height: 800px;
    left: 60%;
}

.errorMessage {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused='true'] {
    border: 1px solid red;
}

input:invalid[focused='true']~.errorMessage {
    display: block;
}

.info p:first-letter {
    text-transform: capitalize;
}

.register-page a {
    color: #ababab;
}

.register-page hr {
    border-top: 0.0625rem solid rgba(190, 27, 27, 0.2);
}

.register-page .footer .column ul:not(.social-buttons) li a:hover {
    color: #ffffff;
}

.features-6 .info:hover svg {
    fill: #fff !important
}

.blogs-4 .card {
    height: 730px !important;
}

.account-settings .terms p {
    font-size: 20px;
}

.account-settings .terms ul {
    margin: 30px 0 50px 0;
}

.account-settings .terms li {
    margin-bottom: 18px;
}

.icon-gradient-primary {
    cursor: pointer;
    background: pink;
    background: -webkit-radial-gradient(33% 100% circle, #5e72e4 4%, #825ee4 30%, #9f0fc3 62%, #6d45f1 85%, #bb73fe);
    background: radial-gradient(circle at 33% 100%, #5e72e4 4%, #825ee4 30%, #9f0fc3 62%, #6d45f1 85%, #bb73fe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font: {
        size: 20vw;
    }

    ;
}

.icon-gradient-warning {
    cursor: pointer;
    background: pink;
    background: -webkit-radial-gradient(33% 100% circle, #fb6340 4%, #e4a85e 30%, #fbb140 62%, #f15645 85%, #fec473);
    background: radial-gradient(circle at 33% 100%, #fb6340 4%, #e4a85e 30%, #fbb140 62%, #f15645 85%, #fec473);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font: {
        size: 20vw;
    }

    ;
}

.icon-gradient-info {
    cursor: pointer;
    background: pink;
    background: -webkit-radial-gradient(33% 100% circle, #11cdef 4%, #11b7ef 30%, #0fbdc3 62%, #45f1e3 85%, #73fed9);
    background: radial-gradient(circle at 33% 100%, #11cdef 4%, #11b7ef 30%, #0fbdc3 62%, #45f1e3 85%, #73fed9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font: {
        size: 20vw;
    }

    ;
}

legend {
    font-size: 15px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
}

ul {
    list-style-type: none;
    padding: 0;
}

i {
    font-size: 25px !important;
}

span {
    font-size: 13px;
    margin-top: 18px;
    margin-right: 10px;
}

.dropdown-menu-xxl {
    min-width: 537px;
    padding: 0 !important;
    overflow: hidden;
}

.footer-image {
    padding: 0 !important;
}

.my-3D-Logo {
    position: fixed;
    z-index: 500;
    top: 100%;
    margin-top: -110px;
    left: 50%;
    width: 200px;
    height: 100px;
}


button.navbar-toggler {
    float: right;
}

@media (min-width: 992px) {
    div#navbar_global {
        width: fit-content;
        float: right;
    }
}

.quick-facts-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.quick-facts-container .all-quick {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
}

@media (max-width: 768px) {
    quick-facts-container ul {
        position: static;
        margin-top: 10px;
    }

    .cell-text-center {
        text-align: center;
    }
}


.footer.footer-simple nav,
.footer.footer-simple .copyright {
    width: 100% !important;
}

.img-height {
    height: 300px;
}

.card-blog img {
    object-fit: cover !important;
}

@media (max-width: 1199.98px) {
    .header-3 .page-header {
        min-height: 100vh;
    }
}